<template>
  <div>
    <div class="form-group-row">
      <input id="emailormobile" class="form-control" type="text" v-model.trim="loginDet" placeholder="Email or Mobile" />
    </div>
    <div class="form-group-row">
      <input id="password" class="form-control" type="password" v-model.trim="passWord" placeholder="Password" />
    </div>
    <div class="form-group-row">
      <button class="btn btn-success" @click="login()">Login</button>
      <router-link :to="{name: 'AddUser'}"><button class="btn btn-primary">Register</button></router-link>
    </div>
  </div>

</template>

<script>

export default {
  name: 'LoginPage',
  data () {
    return {
      loginDet: '',
      passWord: '',
      username: '',
      users: [] 
    }
  },
  inject: ['GStore'],
  methods: {
    login() {
      //this.$store.state.counter++
      for(const user in this.users)
      {
         if((this.loginDet == this.users[user].email || this.loginDet == this.users[user].mobile) && this.passWord == this.users[user].password)
         {
            this.$store.dispatch('setAuth', true)
            this.$store.dispatch('setUser', this.users[user].username)
            this.$store.dispatch('setUserID', this.users[user].uid)
            //console.log("this.users[user].username " + this.users[user].username)
            this.GStore.flashMessage = 'Login Success!'
            this.$router.push({
              name: 'PubApi'
            })
            break;
         }
         else
         {
            console.log('Correct Email: ' + this.users[user].email +  '  my Email: ' + this.loginDet)
            console.log('Correct Mobile: ' + this.users[user].mobile +  '  my Email: ' + this.loginDet)
            console.log('Correct Password: ' + this.users[user].password + '  my Password: ' + this.passWord)
            this.GStore.flashMessage = 'Incorrect Login Details!!!!'
         }
      }   
    },
    async getLogin() {
     console.log('Getting data')
      try {
        const response = await fetch('https://css2assignment-63c75-default-rtdb.europe-west1.firebasedatabase.app/contacts.json', { method: 'GET' })
        const responseData = await response.json()
        if (!response.ok) {
          console.log('Something went wrong')
        }
        const users = []
        for (const id in responseData) {
          users.push({
            uid: responseData[id].uid - 1,
            username: responseData[id].username,
            email: responseData[id].email,
            mobile: responseData[id].mobile,
            password: responseData[id].password,
          })
          this.users = users
        }
        //console.log(users[1].email)
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted () {
    this.getLogin()
  }
}
</script>

<style scoped>

.form-group-row
{
  padding: 20px;
}

.form-control
{
  display: inline-flex;
}

button
{
  margin: 20px;
  padding: 10px;
}

#emailormobile
{
  max-width: 30rem;
  width: 80%;
}
#password
{
  max-width: 30rem;
  width: 80%;
}

</style>
