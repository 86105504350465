<template>
<div class="container">
    <form @submit.prevent="submitForm">
      <h2 class="headerText">Update User</h2>
      <div class="form-group row">
            <label for="name" class="col-md-4 col-form-label">Name</label>
            <div class="col-sm-7">
              <input id="name" class="form-control" type="text" v-model.trim="name"/>
              <!--input @blur="$v.name.$touch()" id="name" class="form-control" type="text" v-model.trim="name"/>
                <div v-if="$v.name.$error">
                    <div v-for="(error, index) of $v.name.$errors" :key="index">
                        <div class="text-danger">{{ error.$message }}</div>
                    </div>
                </div-->
            </div>
        </div>
        <div class="form-group row">
            <label for="surname" class="col-md-4 col-form-label">Surname</label>
            <div class="col-sm-7">
              <input id="surname" class="form-control" type="text" v-model.trim="surname"/>
              <!--input @blur="$v.surname.$touch()" id="surname" class="form-control" type="text" v-model.trim="surname"/>
                <div v-if="$v.surname.$error">
                    <div v-for="(error, index) of $v.surname.$errors" :key="index">
                        <div class="text-danger">{{ error.$message }}</div>
                    </div>
                </div-->
            </div>
        </div>
        <div class="form-group row">
            <label for="Username" class="col-md-4 col-form-label">Username</label>
            <div class="col-sm-7">
              <input id="Username" class="form-control" type="text" v-model.trim="username"/>
              <!--input @blur="$v.username.$touch()" id="surname" class="form-control" type="text" v-model.trim="username"/>
              <div v-if="$v.username.$error">
                  <div v-for="(error, index) of $v.username.$errors" :key="index">
                      <div class="text-danger">{{ error.$message }}</div>
                  </div>
              </div-->
            </div>
        </div>
        <div class="form-group row">
            <label for="password" class="col-md-4 col-form-label">Password</label>
            <div class="col-sm-7">
              <input id="password" class="form-control" type="password" v-model.trim="password"/>
              <!--input @blur="$v.username.$touch()" id="password" class="form-control" type="password" v-model.trim="password"/>
              <div v-if="$v.password.$error">
                  <div v-for="(error, index) of $v.password.$errors" :key="index">
                      <div class="text-danger">{{ error.$message }}</div>
                  </div>
              </div-->
            </div>
        </div>
        <div class="form-group row">
            <label for="dob" class="col-md-4 col-form-label">Date of Birth</label>
            <div class="col-sm-7">
              <input id="dob" class="form-control" type="date" v-model.trim="dob"/>
              <!--input @blur="$v.dob.$touch()" id="dob" class="form-control" type="date" v-model.trim="dob"/>
              <div v-if="$v.dob.$error">
                  <div v-for="(error, index) of $v.dob.$errors" :key="index">
                      <div class="text-danger">{{ error.$message }}</div>
                  </div>
              </div-->
            </div>
        </div>
        <div class="form-group row">
            <label for="email" class="col-md-4 col-form-label">Email</label>
            <div class="col-sm-7">
              <input id="email" class="form-control" type="email" v-model.trim="email"/>
              <!--input @blur="$v.email.$touch()" id="email" class="form-control" type="email" v-model.trim="email"/>
                <div v-if="$v.email.$error">
                  <div v-for="(error, index) of $v.email.$errors" :key="index">
                      <div class="text-danger">{{ error.$message }}</div>
                  </div>
                </div-->
            </div>
        </div>
        <div class="form-group row">
            <label for="mobile" class="col-md-4 col-form-label">Mobile</label>
            <div class="col-sm-7">
                <!--select @blur="$v.countrycode.$touch()" id="countrycode" name="countrycode" class="form-control" v-model.trim="countrycode"-->
                <select id="countrycode" name="countrycode" class="form-control" v-model.trim="countrycode">
                    <option value="356">MT</option>
                    <option value="380">IT</option>
                    <option value="276">DE</option>
                    <option value="826">GB</option>
                    <option value="840">US</option>
                </select>
                <input id="mobile" class="form-control" type="tel" v-model.trim="mobile"/>
                <!--input @blur="$v.mobile.$touch()" id="mobile" class="form-control" type="tel" v-model.trim="mobile"/>
                <div v-if="$v.mobile.$error">
                    <div v-for="(error, index) of $v.mobile.$errors" :key="index">
                        <div class="text-danger">{{ error.$message }}</div>
                    </div>
                </div-->
            </div>
        </div>
        <div class="form-group">   
            <router-link class="task-link" :to="{name: 'ViewUser', params:{id: userId}}"><button type="button" id="back" class="btn btn-secondary">Back</button></router-link>
            <!--<div v-if="$v.$anyError" class="text-danger">Please fill in all fields correctly.</div>-->
            <button data-toggle="modal" data-target="#myModal" @click="submitForm" type="button" id="submit" class="btn btn-success">Yes</button> 
        </div>
  </form>
</div>
</template>

<script>
//import { required, minLength, maxLength, alpha, numeric, alphaNum, email,  sameAs} from '@vuelidate/validators'

export default {
  //emits: ['set-data'],
  data() {
    return {
      uid:  0,
      name:'',
      surname:'',
      username:'',
      password:'',
      dob:'',
      email:'',
      countrycode:'',
      mobile:'',
      updateMode: false
    };
  },
  /*validations(){
    return{
        name:{required, minLength: minLength(4), maxLength: maxLength(15), alpha},
        surname:{required, minLength: minLength(4), maxLength: maxLength(15), alpha},
        name:{required},
        dob:{required},
        mobile:{required, numeric,minLength: minLength(8), maxLength: maxLength(8)},
        countrycode:{required},
        email:{required, email},
        password:{required,minLength: minLength(3)},
    }
  },*/
  inject: ['GStore'],
  methods: 
  {
    async submitForm() 
    {
      //const id = this.$route.params.id;
      //this.$v.$touch();
      /*if(!this.$v.$invalid)
      {
        try catch supposedly goes here
      }*/
      try
      {
        this.GStore.flashMessage = 'User ' + this.username + ' registered'
        this.$router.push({
        name: 'PubApi',
        })
        //we will submit only if the form does not contain anything invalid
        console.log("Getting data");        
        const response = await fetch('https://css2assignment-63c75-default-rtdb.europe-west1.firebasedatabase.app/contacts/' + this.uid + '.json',{
          method: 'PATCH',
          headers:
          {
            'Content-Type':'application/json'
          },
          body: JSON.stringify({
              uid: this.uid + 1 ,
              name: this.name,
              surname: this.surname,
              username: this.username,
              password:  this.password,
              dob: this.dob,
              email: this.email,
              countrycode: this.countrycode,
              mobile: this.mobile
          })
        })
        if(!response.ok){
        console.log("Something went wrong");
        }
      }
      catch(error)
      {
          console.log(error);
      }
      this.$store.dispatch('setUser', this.username)
    },
    async getData() {
      console.log('Getting data')
      const id = this.$route.params.uid
      try {
        const response = await fetch('https://css2assignment-63c75-default-rtdb.europe-west1.firebasedatabase.app/contacts/' + id + '.json', {
          method: 'GET'
        })
        const responseData = await response.json()
        if (!response.ok) {
          console.log('Something went wrong')
        }
        console.log(responseData)
        this.uid = responseData.uid - 1 
        this.dob = responseData.dob
        this.email = responseData.email
        this.mobile = responseData.mobile
        this.countrycode = responseData.countrycode
        this.username = responseData.username,
        this.name = responseData.name,
        this.surname = responseData.surname
      } catch (error) {
        console.log(error)
      }
    }
  },
  //lifecycle hook which is called before component is shown on the screen but all data is available
  created(){
      //console.log("Yo yo" + this.$route.params.memberId);
      if(typeof this.$route.params.id !== 'undefined')
      {
          console.log("Hey" + this.$route.params.id);
          //console.log(this.$route);
          const userId = this.$route.params.uid;
          this.updateMode = true;
          //if no id user will add a member. If there is an id user will update so we need to populate form with data
          if(userId!=='')
          {
              this.getData();
          }
      }
  }

};
</script>

<!--The style block with the scoped attribute will overwrite the global 
styles and will effect this component only. -->
<style scoped>

.container
{
    padding-top: 20px;
    width: 1000px;
}

.headerText
{
    padding: 10px;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
}

form {
  display: inline-block;
  max-width: 40rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 5%;
  background-color: #2e2222c4;
}

label {
    font-weight: bolder;
    color: white; 
}

.form-control
{
    position: relative;
}

#countrycode
{
    width: 35%;
    float: left;
}

#mobile
{
  float: right;
  position: relative;
  width: 60%;
    
}

button {
  font: inherit;
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  text-align: center;
  align-content: center;
  margin: 2%;
}


button:hover,
button:active {
  border-color: #002350;
  background-color: #002350;
}
</style>