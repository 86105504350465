<template>
<div class="container">
    <form @submit.prevent="submitForm">
      <h2 class="headerText">Update the Anime</h2>
        <div class="form-group row">
            <label for="title" class="col-md-3 col-form-label">Title</label>
            <div class="col-sm-6">
              <input id="title" class="form-control" type="text" v-model.trim="title"/>
              <!--input @blur="$v.title.$touch()" id="title" class="form-control" type="text" v-model.trim="title"/>
                <div v-if="$v.title.$error">
                    <div v-for="(error, index) of $v.title.$errors" :key="index">
                        <div class="text-danger">{{ error.$message }}</div>
                    </div>
                </div-->
            </div>
        </div>
        <div class="form-group row">
            <label for="description" class="col-md-3 col-form-label">Desciption</label>
            <div class="col-sm-6">
              <input id="description" class="form-control" type="text" v-model.trim="description"/>
              <!--input @blur="$v.description.$touch()" id="description" class="form-control" type="text" v-model.trim="description"/>
                <div v-if="$v.description.$error">
                    <div v-for="(error, index) of $v.description.$errors" :key="index">
                        <div class="text-danger">{{ error.$message }}</div>
                    </div>
                </div-->
            </div>
        </div>
        <div class="form-group row">
            <label for="releasedate" class="col-md-3 col-form-label">Release Date</label>
            <div class="col-sm-6">
              <input id="releasedate" class="form-control" type="date" v-model.trim="releasedate"/>
              <!--input @blur="$v.releasedate.$touch()" id="releasedate" class="form-control" type="date" v-model.trim="releasedate"/>
                <div v-if="$v.releasedate.$error">
                    <div v-for="(error, index) of $v.releasedate.$errors" :key="index">
                        <div class="text-danger">{{ error.$message }}</div>
                    </div>
                </div-->
            </div>         
        </div>
        <div class="form-group row">
            <label for="episodes" class="col-md-3 col-form-label">Episodes</label>
            <div class="col-sm-6">
                <input id="episodes" class="form-control" type="number" v-model.number="episodes"/>
                <!--input @blur="$v.episodes.$touch()" id="episodes" class="form-control" type="number" v-model.number="episodes"/>
                <div v-if="$v.episodes.$error">
                    <div v-for="(error, index) of $v.episodes.$errors" :key="index">
                        <div class="text-danger">{{ error.$message }}</div>
                    </div>
                </div-->
            </div>
        </div>
        <div class="form-group">   
            <router-link class="task-link" :to="{name: 'ViewLocal', params:{id: id}}"><button type="button" id="back" class="btn btn-secondary">Back</button></router-link>
            <!--<div v-if="$v.$anyError" class="text-danger">Please fill in all fields correctly.</div>-->
            <button data-toggle="modal" data-target="#myModal" @click="submitForm" type="button" id="submit" class="btn btn-success">Yes</button> 
        </div>
    </form>
  </div>
</template>

<script>
export default {
  //emits: ['set-data'],
  data() {
    return {
      id:  0,
      title:'',
      description:'',
      episodes:'',
      releasedate:'',
      updateMode: false

    };
  },
  /*validations(){
    return{
        title: {required},
        description: {required},
        episodes: {required, numeric},
        releasedate: {required}
    }
  },*/
  inject: ['GStore'],
  methods: 
  {
    async submitForm() 
    {
      //const id = this.$route.params.id;

      /*this.$v.$touch();
      if(!this.$v.$invalid)
      {
        try and catch go here
      }
      */
      try
      {
        this.GStore.flashMessage = 'Anime ' + this.title + ' was updated'

        this.$router.push({
        name: 'DisplayLocal'
        // the above line of code is optional. id will get populated by default
        })
        //we will submit only if the form does not contain anything invalid
        console.log("Getting data");        
        const response = await fetch('https://css2assignment-63c75-default-rtdb.europe-west1.firebasedatabase.app/animes/' + this.id + '.json',{
          method: 'PATCH',
          headers:
          {
            'Content-Type':'application/json'
          },
          body: JSON.stringify({
              id: this.id + 1 ,
              title: this.title,
              releasedate:  this.releasedate,
              description: this.description,
              episodes: this.episodes
          })
        })
        if(!response.ok){
        console.log("Something went wrong");
        }
      }
      catch(error)
      {
          console.log(error);
      }
    },
    async getData() {
      console.log("Getting data");
      const id = this.$route.params.id;
      try {
          const response = await fetch('https://css2assignment-63c75-default-rtdb.europe-west1.firebasedatabase.app/animes/' + id + '.json', {
          method: 'GET'
          })
          const responseData = await response.json();
          if (!response.ok) {
          console.log("Something went wrong")
          }
          console.log(responseData)
          console.log(responseData.title)
          this.id = responseData.id - 1;
          this.title = responseData.title;
          this.releasedate = responseData.releasedate;
          this.description = responseData.description;
          this.episodes = responseData.episodes;
      } catch (error) {
          console.log(error);
      }
    }
  },
  //lifecycle hook which is called before component is shown on the screen but all data is available
  created(){
      //console.log("Yo yo" + this.$route.params.memberId);
      if(typeof this.$route.params.id !== 'undefined')
      {
          console.log("Hey" + this.$route.params.id);
          //console.log(this.$route);
          const animId = this.$route.params.id;
          this.updateMode = true;
          //if no id user will add a member. If there is an id user will update so we need to populate form with data
          if(animId!=='')
          {
              this.getData();
          }
      }
  }

};
</script>

<!--The style block with the scoped attribute will overwrite the global 
styles and will effect this component only. -->
<style scoped>

.container
{
    padding-top: 20px;
}


.text-danger
{
    position: relative;
    padding: 10px;
    left: 190px;
}

.headerText
{
    padding: 10px;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
}

form {
  display: inline-block;
  max-width: 40rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 50px;
  background-color: #2e2222c4;
}


label {
    float: left;
    font-weight: bolder;
    width: 80rem;
    color: white; 
}


button {
  font: inherit;
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  text-align: center;
  align-content: center;
  margin:2%;
}

button:hover,
button:active {
  border-color: #002350;
  background-color: #002350;
}
</style>