<template>
  <div>
    <input type="text" v-model="searchParam" placeholder="Name" />
    <button class="btn btn-success" @click="searchRequest">Search</button>
    <transition-group tag="ul" appear name="slide-up" class="list-group list-group-flush">
      <div v-if="animes != null" class="animes">
        <div class="card" style="width: 24rem">
          <div class="card-header">Animes</div>
          <Anime v-for="anime in animes" :key="anime" :anime="anime"></Anime>
        </div>
      </div>
    </transition-group>
  </div>

</template>

<script>
// @ is an alias to /src
import Anime from '@/components/Anime.vue'
// import axios from 'axios'
import PublicSearch from '@/services/PublicSearch.js'

export default {
  name: 'PubApi',
  data () {
    return {
      searchParam: '',
      animes: null,
      totalAnimes: 0
    }
  },
  methods: {
    searchRequest () {
      PublicSearch.getTasks(this.searchParam)
      .then(response => {
        this.animes = response.data.results
        this.totalAnimes = this.animes.length
        console.log(this.animes)
        console.log(this.totalAnimes)
      }).catch(function (error) {
        console.error('Error ' + error)
      })
    }
  },
  components: {
    Anime
  }
}
</script>

<style scoped>
  .animes {
    padding: 3%;
  }

  button
  {
    margin: 1%;
  }

  .card {
    border-radius: 20px;
    display: flex;
    padding: 20px;
    margin: auto;
    background-color: #a3000094;
    color: white;
  }

</style>
