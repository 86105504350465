<template>
  <div class="container">
        <h2>This was a Client Side Scripting Assignment</h2>
        <p>Made By Justin Schembri MSD 6.2 A</p>
  </div>
</template>

<style scoped>
.container
{
    position: relative;
    top: 50px;
    display: inline-block;
    max-width: 40rem;
    border-radius: 12px;
    padding: 50px;
    background-color: #501717bd;
    text-align: center;
}

h2
{
  color: rgb(255, 255, 255);
}

.text
{
    padding: 10px;
    color: rgb(255, 255, 255);
}

</style>