<template>
  <div v-if="anime" class="anime-details">
    <h1>{{anime.title}}</h1>
  <router-view :anime="anime" v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>

  </div>
</template>

<script>
import PublicSearch from '@/services/PublicSearch.js'

export default {
  props: ['id'],
  data () {
    return {
      anime: null
    }
  },
  created () {
    PublicSearch.getTask(this.id)
      .then(response => {
        console.log('anime: ', response.data.episodes)
        this.anime = response.data.episodes
      })
      .catch(error => {
        console.log('ERRORS' + error)
      })
  }
}
</script>
<style scoped>
  .anime-details{
    border-radius: 20px;
    background-color: #89023fea;
    width:40%;
    margin:15px auto;
    padding:10px;
  }
</style>
