<template>
<div>
  <ol id="example-2">
    <li v-for="episodes in anime" :key="episodes.episode_id" :anime="anime">
      {{lowercase(episodes.title)}}
    </li>
  </ol>
</div>
</template>

<script>
export default {
  props: ['anime'],
  methods:
  {
    lowercase(title)
    {
      return title.toLowerCase()
    }
  }
}

</script>
