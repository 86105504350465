import axios from 'axios'

const options = axios.create({
  method: 'GET',
  baseURL: 'https://jikan1.p.rapidapi.com/search',
  headers: {
    'x-rapidapi-key': 'dc1e94babemsh3f2bccf97430619p170c29jsna61b624b6c39',
    'x-rapidapi-host': 'jikan1.p.rapidapi.com'
  }
})

/* const apiClient = axios.create({
  baseURL: 'https://jikan1.p.rapidapi.com/search/',
  method: 'GET',
  params: { q: 'Dragon Ball' },
  headers: {
    'x-rapidapi-key': 'dc1e94babemsh3f2bccf97430619p170c29jsna61b624b6c39',
    'x-rapidapi-host': 'jikan1.p.rapidapi.com',
  }
}) */

const indepth = axios.create({
  method: 'GET',
  baseURL: 'https://jikan1.p.rapidapi.com/anime/',
  headers: {
    'x-rapidapi-key': 'dc1e94babemsh3f2bccf97430619p170c29jsna61b624b6c39',
    'x-rapidapi-host': 'jikan1.p.rapidapi.com'
  }
})

export default {
  getTasks (search) {
    return options.get('/anime' + '?q=' + search)
  },
  getTask (id) {
    return indepth.get(id + '/episodes')
  }
}
