<template>
  <div class="container">
        <h2>{{title}}</h2>
        <div class="text">Description: {{description}}</div>
        <div class="text">Episodes: {{episodes}}</div>
        <div class="text">ReleaseDate: {{releasedate}}</div>
        <div class="form-group">
            <router-link class="task-link" :to="{name: 'DisplayLocal'}"><button type="button" id="back" class="btn btn-secondary">Back</button></router-link>
            <router-link class="task-link" :to="{name: 'UpdateLocal', params:{id: id}}"><button type="button" id="update" class="btn btn-primary">Edit</button></router-link>
        </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      id: 0,
      description: '',
      episodes: '',
      releasedate: '',
      title: '',

    }
  },
  methods: {      
    async getData () {
      console.log('Getting data')
      const id = this.$route.params.id
      try {
        const response = await fetch('https://css2assignment-63c75-default-rtdb.europe-west1.firebasedatabase.app/animes/' + id + '.json', {
          method: 'GET'
        })
        const responseData = await response.json()
        if (!response.ok) {
          console.log('Something went wrong')
        }
        console.log(responseData)
        this.id = responseData.id - 1 
        this.description = responseData.description
        this.episodes = responseData.episodes
        this.releasedate = responseData.releasedate
        this.title = responseData.title
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style scoped>

button
{
   margin: 10px;
}

.container
{
    position: relative;
    top: 50px;
    display: inline-block;
    max-width: 40rem;
    border-radius: 12px;
    padding: 50px;
    background-color: #501717bd;
    text-align: center;
}

h2
{
  color: rgb(255, 255, 255);
}

.text
{
    padding: 10px;
    color: rgb(255, 255, 255);
}

</style>
