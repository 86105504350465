<template>
  <div class="container">
        <h2>{{UpperCase()}}</h2>
        <div class="text">Name: {{name}}</div>
        <div class="text">Surname: {{surname}}</div>
        <div class="text">Date of Birth: {{dob}}</div>
        <div class="text">Email: {{email}}</div>
        <div class="text">Mobile: {{countryCodePlus()}} {{mobile}}</div>
        <div class="form-group">
            <router-link class="task-link" :to="{name: 'PubApi'}"><button type="button" id="back" class="btn btn-secondary">Back</button></router-link>
            <router-link :to="{name: 'UpdateUser', params:{id: uid}}"><button type="button" id="update" class="btn btn-primary">Update</button></router-link>
            <router-link :to="{name: 'DeleteUser', params:{id: uid}}"><button type="button" id="delete" class="btn btn-danger">Delete</button></router-link>
        </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      uid:  0,
      username:'',
      name:'',
      surname:'',
      password:'',
      dob:'',
      email:'',
      countrycode:'',
      mobile:''
    }
  },
  methods: {     
    UpperCase()
    {
      return this.username.toUpperCase()
    },
    countryCodePlus()
    {
      return "+" + this.countrycode
    },
    async getData () {
      console.log('Getting data')
      const id = this.$route.params.uid
      try {
        const response = await fetch('https://css2assignment-63c75-default-rtdb.europe-west1.firebasedatabase.app/contacts/' + id + '.json', {
          method: 'GET'
        })
        const responseData = await response.json()
        if (!response.ok) {
          console.log('Something went wrong')
        }
        console.log(responseData)
        this.uid = responseData.uid - 1 
        this.dob = responseData.dob
        this.email = responseData.email
        this.mobile = responseData.mobile
        this.countrycode = responseData.countrycode
        this.username = responseData.username,
        this.name = responseData.name,
        this.surname = responseData.surname
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted () {
    this.getData()
  }
  
}
</script>

<style scoped>


button
{
  margin:1%;
}

.container
{
    position: relative;
    top: 50px;
    display: inline-block;
    max-width: 40rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 50px;
    background-color: #501717bd;
    text-align: center;
}

h2
{
  color: white;
}

.text
{
    padding: 10px;
    color: white;
}

</style>
