import { createApp } from 'vue'
import { createStore } from 'vuex'

const loggedInModule = {
    state() {
        return{
            isloggedIn: 0,
            username: '',
            userid: 0
        }
    },
    mutations: {
        setAuth(state, payload) {
            console.log("In mutation setting isLoggedIn to" + payload)
            state.isLoggedIn = payload
        },
        setUser(state, username)
        {
            state.User = username
        },
        setUserID(state, userid)
        {
            state.UserID = userid
        }
    },
    actions: {
        setAuth (context, payload) {
            console.log(payload)
            context.commit('setAuth', payload)
        },
        setUser(context, username)
        {
            console.log(username) 
            context.commit('setUser', username)
        },
        setUserID(context, userid)
        {
            console.log(userid) 
            context.commit('setUserID', userid)
        }
    },
    getters: {
        userIsAuthenticated(state) {
            return state.isLoggedIn
        },
        getUser(state)
        {
            return state.User
        },
        getUserID(state)
        {
            return state.UserID
        }
    }
}

const counterModule = {
  state() {
      return{
          counter: 0
      }
  },
  mutations: {
      increment (state) {
          state.counter++
      },
      increase(state, value) {
          state.counter = state.counter + value
      }
  },
  actions: {
      increment (context) {
          setTimeout(function() {
              context.commit('increment')
          },2000)
      },
      increase (context, value) {
          context.commit('increase', value)
      }
  },
  getters: {
      finalCounter(state) {
          return state.counter
      }
  }
}

export default createStore({
  modules: {
      counter: counterModule,
      loggedIn: loggedInModule
  }
})
