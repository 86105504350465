<template>
  <div class="container">
        <h2>Are You Sure?</h2>
        <p>you want to delete</p>
        <div class="form-group">
            <router-link class="task-link" :to="{name: 'PubApi'}"><button type="button" id="back" class="btn btn-secondary">No</button></router-link>
            <button data-toggle="modal" data-target="#myModal" @click="deleteRecord" type="button" id="delete" class="btn btn-danger">Yes</button> 
        </div>
  </div>
 
</template>

<script>
export default {
  data() {
    return {
      dob:'',
      email:'',
      mobile:'',
      countrycode:'',
      username:'',
      deleteMode: true
    };
  },
  inject: ['GStore'],
  methods: {
    async deleteRecord() {
        const id = this.$route.params.uid;
        try
        {
          this.GStore.flashMessage = 'User Deleted'
          this.$store.dispatch('setAuth', false)
          this.$router.push({
            name: 'PubApi'
          })
          // the above line of code is optional. id will get populated by default
            return fetch('https://css2assignment-63c75-default-rtdb.europe-west1.firebasedatabase.app/contacts/' + id + '.json',{
                method: 'DELETE'
            }).then(response => response.json)
        }
        catch(error)
        {
            console.log(error);
        }      
    }
  }
};

</script>

<style scoped>

button
{
  margin: 2%;
}

.container
{
    position: relative;
    top: 50px;
    display: inline-block;
    max-width: 40rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 50px;
    background-color: #501717bd;
    text-align: center;
}

h2
{
  color: white;
}

.text
{
    padding: 10px;
    color: white;
}

p
{
  color: white;
}
</style>