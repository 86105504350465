<template>
    <router-link class="task-link" :to="{name: 'LoginPage'}"><button id="loginB" class="btn btn-success" v-if="!isAuth"> Login</button></router-link>
    <button id="logoutB" class="btn btn-danger" @click="logout" v-if="isAuth"> Logout</button>
</template>

<script>

export default {
  methods: {
    logout() {
      //this.$store.state.counter++
      this.$store.dispatch('setAuth', false)
      this.$router.push({
        name: 'PubApi'
      })
    }
  },
  computed: {
      isAuth() {
          return this.$store.getters.userIsAuthenticated
      }
  }
};
</script>