<template>
  <transition name="fade">
    <div v-if="GStore.flashMessage">
        {{ GStore.flashMessage }}
        <button @click="GStore.flashMessage = ''" class="btn btn-secondary">Close</button>
    </div>
  </transition>

  <div id="nav-main">
    <!--Instead of using hard-coded paths we will use the names instead-->
    <!--<router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>-->
    <base-container class="loginButton" title="Auth">
      <auth-user ></auth-user>
    </base-container>

    <base-container title="Vuex" v-if="isAuth">
      <the-counter></the-counter>
      <router-link :to="{name: 'ViewUser', params:{uid: userId}}"><b>Welcome {{ initials() }} | </b></router-link>
      <change-counter></change-counter>
    </base-container>

    <router-link :to="{name: 'PubApi'}">Search Anime</router-link>|
    <router-link :to="{name: 'About'}">About</router-link>
    <base-container title="Vuex" v-if="isAuth">
      <the-counter></the-counter>
      | <router-link :to="{name: 'DisplayLocal'}">Community List</router-link>
      <change-counter></change-counter>
    </base-container>
  </div>
  <!-- current element transitions out first and then the new element transitions in -->
    <router-view v-slot="{ Component }">
      <transition name="slide-fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
</template>

<script>
import AuthUser from './components/AuthUser.vue';
export default {
  inject: ['GStore'],
  components: 
  {
    AuthUser
  },
  data () {
    return {
      username: '',
      users: [] 
    }
  },
  methods: {
    initials()
    {
       return this.userN.charAt(0).toUpperCase()
    },
  },
  computed: {
      isAuth() {
        return this.$store.getters.userIsAuthenticated
      },
      userN()
      {
        console.log(this.$store.getters.getUser)
        return this.$store.getters.getUser
      },
      userId()
      {
        console.log(this.$store.getters.getUserID)
        return this.$store.getters.getUserID
      }
  },
  mounted () {
    this.$router.push({
      name: 'PubApi'
    })
  }
};
</script>

<style>

body, html {
  height: 100%;
}

#app {
  width:100%;
  height: 100%;
  margin:auto;
  /* border:1px solid black; */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #e6fdff;
}


#nav-main
{
  background-color: rgba(0, 0, 0, 0.418);
}

#nav-main{
  padding: 30px;
  width: 100%;
}

#nav-main a, #nav a{
  padding: 10px;
  font-weight: bold;
  color: #e6fdff;
}

#nav-main a.router-link-exact-active, #nav a.router-link-exact-active {
  color: #84babe;
}

/* FADE TRANSITION */

/* What style are we starting from and leaving to? Invisible */
/* We are transitioning to opacity 1 which is the default so we do not need to add this */

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* What is the active entering and leaving style? */
.fade-enter-active {
  transition: opacity 0.5s ease-in;
}

.fade-leave-active {
  transition: opacity 0.5s ease-in;
}

/* SLIDE-FADE TRANSITION */

.slide-fade-leave-to {
  transform: translateX(50px);
  opacity: 0;
}

.slide-fade-enter-from{
  transform: translateX(-50px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  /* transition: opacity 1s ease-out; */
  transition: all 2s ease;
}

/* SLIDE-UP TRANSITION */

.slide-up-enter-from{
  transform: translateY(10px);
  opacity: 0;
}

.slide-up-enter-active {
  /* transition: opacity 1s ease-out; */
  transition: all 1s ease;
}

</style>
