<template>
  <div class="tasks">
    <div  class="card" style="width: 24rem;">
      <div class="card-header">
          Animes
          <router-link class="task-link" :to="{name: 'AddLocal'}"><button type="button" id="addLocal" class="btn btn-success" >+<span> Add Anime</span></button></router-link>
      </div>
      <div v-if="results.length">
        <div v-for="res in results" :key="res.id">
          <li class="list-group-item">
            <b class="task-link">{{res.title}}</b>
            <div id="buttons">
              <router-link class="task-link" :to="{name: 'ViewLocal', params:{id: res.id}}">
                <button type="button" id="viewLocal" class="btn btn-primary">View<i class="bi bi-view-list"></i></button>
              </router-link>
              <router-link class="task-link" :to="{name: 'DeleteLocal', params:{id: res.id}}">
                <button type="button" id="deleteLocal" class="btn btn-danger">Delete<i class="bi bi-trash-fill"></i></button>
              </router-link>
            </div>
          </li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default
{
  data () {
    return {
      results: []
    }
  },
  methods:
  {
    // we can connect this to a button as well
    async getData () {
      console.log('Getting data')
      try {
        const response = await fetch('https://css2assignment-63c75-default-rtdb.europe-west1.firebasedatabase.app/animes.json', { method: 'GET' })
        const responseData = await response.json()
        if (!response.ok) {
          console.log('Something went wrong')
        }
        console.log(responseData)
        const results = []
        for (const id in responseData) {
          results.push({
            id: id,
            description: responseData[id].description,
            episodes: responseData[id].episodes,
            releasedate: responseData[id].releasedate,
            title: responseData[id].title
          })
          //console.log(responseData[id].id)
          this.results = results
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  // when component is fully initialised call method
  mounted () {
    this.getData()
  }
}
</script>

<style scoped>
.tasks
{
  padding: 3%;
}

.card{
  border-radius: 20px;
  display: flex;
  padding: 2%;
  margin:auto;
  background-color: #a3000094;
  color: white;
}

#addLocal
{
  overflow: hidden;
  border-radius: 20px;
  transition: all .2s ease-in-out;
}

span
{
  opacity: 0;
  transition: all .2s ease-in-out;
}

#addLocal:hover span
{
  opacity: 1;
  width: 100px;
  transition: all .2s ease-in-out;
}

#addLocal:hover
{
  box-shadow: 0 3px 12px 0;
  transition: all .2s ease-in-out;
}

#viewLocal
{
  margin-right: 5%;
  width: 30%;
}

.task-link{
  text-decoration:none;
  /*color: #0A1128;*/
}

.list-group-item:hover{
  border-width: 0px;
  transform:scale(1.01);
  box-shadow: 0 3px 12px 0;
  transition: all .2s ease-in-out;
  margin: 5%;

}

.list-group-item
{
  border-width: 5px;
  margin: 2%;
  background-blend-mode: overlay;
  background-color: rgba(34, 6, 6, 0.493);
  transition: all .2s ease-in-out;
}

</style>
