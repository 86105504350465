import { createRouter, createWebHistory } from 'vue-router'
import About from '@/views/About.vue'
import PubApi from '@/views/PubApi.vue'
import AnimePubLayout from '@/views/animeIndividual/PubLayout.vue'
import AnimePubDetails from '@/views/animeIndividual/PubDetails.vue'
import NotFoundComponent from '@/views/NotFoundComponent.vue'
import DisplayLocal from '@/views/DisplayLocal.vue'
import ViewLocal from '@/views/LocalData/ViewLocal.vue'
import UpdateLocal from '@/views/LocalData/UpdateLocal.vue'
import DeleteLocal from '@/views/LocalData/DeleteLocal.vue'
import AddLocal from '@/views/LocalData/AddLocal.vue'
import LoginPage from '@/views/LoginPage.vue'
import AddUser from '@/views/UserData/AddUser.vue'
import ViewUser from '@/views/UserData/ViewUser.vue'
import UpdateUser from '@/views/UserData/UpdateUser.vue'
import DeleteUser from '@/views/UserData/DeleteUser.vue'

const routes = [
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/',
    name: 'PubApi',
    component: PubApi,
    props: route => ({ page: parseInt(route.query.page) || 1 })
  },
  {
    path: '/loginpage',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/addlocal',
    name: 'AddLocal',
    component: AddLocal
  },
  {
    path: '/adduser',
    name: 'AddUser',
    component: AddUser
  },
  {
    path: '/displaylocal',
    name: 'DisplayLocal',
    component: DisplayLocal
  },
  {
    path: '/viewuser/:uid',
    name: 'ViewUser',
    component: ViewUser
  },
  {
    path: '/updateuser/:uid',
    name: 'UpdateUser',
    component: UpdateUser
  },
  {
    path: '/deleteuser/:uid',
    name: 'DeleteUser',
    component: DeleteUser
  },
  {
    path: '/viewlocal/:id',
    name: 'ViewLocal',
    component: ViewLocal
  },
  {
    path: '/deletelocal/:id',
    name: 'DeleteLocal',
    component: DeleteLocal
  },
  {
    path: '/updatelocal/:id',
    name: 'UpdateLocal',
    component: UpdateLocal
  },
  {
    path: '/anime',
    name: 'AnimePubLayout',
    props: true,
    component: AnimePubLayout,
    children: [
      { // nest child components
        path: '', // this will be loaded at the root path of the parent
        name: 'AnimePubDetails',
        component: AnimePubDetails
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    component: NotFoundComponent
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
