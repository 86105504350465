<template>
  <div class="container">
        <h2>Delete Anime</h2>
        <div class="text">{{title}}</div>
        <div class="text">Description: {{description}}</div>
        <div class="text">Episodes: {{episodes}}</div>
        <div class="text">ReleaseDate: {{releasedate}}</div>

        <h2>Are You Sure?</h2>
        <div class="form-group">
            <router-link class="task-link" :to="{name: 'DisplayLocal'}"><button type="button" id="back" class="btn btn-secondary">No</button></router-link>
            <button data-toggle="modal" data-target="#myModal" @click="deleteRecord" type="button" id="delete" class="btn btn-danger">Yes</button> 
        </div>
  </div>
 
</template>

<script>
export default {
  data() {
    return {
      description: '',
      episodes: '',
      releasedate: '',
      title: '',
      deleteMode: true
    };
  },
  inject: ['GStore'],
  methods: {     
    async deleteRecord() {
        const id = this.$route.params.id;
        try
        {
          this.GStore.flashMessage = 'Anime ' + this.title + ' was deleted'

          this.$router.push({
          name: 'DisplayLocal'
          // the above line of code is optional. id will get populated by default
          })
            return fetch('https://css2assignment-63c75-default-rtdb.europe-west1.firebasedatabase.app/animes/' + id + '.json',{
                method: 'DELETE'
            }).then(response => response.json)
        }
        catch(error)
        {
            console.log(error);
        }
    },  
    async getData() {
      console.log("Getting data");
      const id = this.$route.params.id;
      try {
        const response = await fetch('https://css2assignment-63c75-default-rtdb.europe-west1.firebasedatabase.app/animes/' + id + '.json', {
          method: 'GET'
        })
        const responseData = await response.json();
        if (!response.ok) {
          console.log("Something went wrong")
        }

        this.description = responseData.description
        this.episodes = responseData.episodes
        this.releasedate = responseData.releasedate
        this.title = responseData.title

      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    this.getData();
  },
};

</script>

<style scoped>

button
{
  margin: 2%;
}

.container
{
    position: relative;
    top: 50px;
    display: inline-block;
    max-width: 40rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 50px;
    background-color: #501717bd;
    text-align: center;
}

h2
{
  color: white;
}

.text
{
    padding: 10px;
    color: white;
}
</style>