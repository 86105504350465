<template>
      <ul class="list-group list-group-flush">
        <!--<router-link to="/tasks/123">-->
        <router-link class="task-link" :to="{name: 'AnimePubDetails', params:{id: anime.mal_id}}">
        <li class="list-group-item">
          <b>{{UpperCase()}}</b>
        </li>
        </router-link>
       </ul>
</template>

<script>
export default {
  name: 'Anime Card',

  props: {
    anime: Object
  },

  methods:
  {
    UpperCase()
    {
       return this.anime.title.toUpperCase()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.task-link{
  text-decoration:none;
  color: #0A1128;
}

.list-group-item:hover{
  transform:scale(1.01);
  box-shadow: 0 3px 12px 0;
  margin: 5px

}
</style>
